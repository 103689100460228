





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DataItemDiff",
})
export default class DataItemDiff extends Vue {
  @Prop({ required: false })
  label!: string;

  @Prop({ required: true })
  before!: any;

  @Prop({ required: true })
  after!: any;

  @Prop({ required: false, default: false })
  noBorder?: boolean | string;

  get areEqual(): boolean {
    let equal = this.before === this.after;
    if (!equal) {
      if (!this.before && !this.after) {
        equal = true;
      }
    }
    return equal;
  }

  get classList() {
    const noBorder =
      this.noBorder === true ||
      (typeof this.noBorder === "string" &&
        (!this.noBorder || this.noBorder === "true"));
    const result = [];
    if (noBorder) {
      result.push("no-border");
    }
    if (!this.areEqual) {
      result.push("no-equal");
    }
    return result;
  }
}
