






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DataDiffContainer",
})
export default class DataDiffContainer extends Vue {
  @Prop({ required: false, default: 180 })
  labelWidth!: number;

  hasUpdateLabelWidth = false;

  mounted() {
    if (!this.hasUpdateLabelWidth) {
      return;
    }

    this.$el.querySelectorAll(".diff-row dl dt").forEach((item) => {
      (item as any).style.width = this.labelWidth + "px";
    });
    this.hasUpdateLabelWidth = true;
  }
}
