









































































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  FundDto,
  FundModifyApplyDto,
} from "@/api/appService";
import api from "@/api";

import ExportWord from "@/components/DownloadWord/index.vue";

import AttachmentsView from "@/components/AttachmentsView/index.vue";
import DataDiffContainer from "@/components/DataItemDiff/DataItemDiffContainer.vue";
import DataDiffTitle from "@/components/DataItemDiff/DataDiffTitle.vue";
import DataItemDiff from "@/components/DataItemDiff/DataItemDiff.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "fundModifyApplyDetail",
  components: {
    AuditBlock,
    DataItemDiff,
    DataDiffTitle,
    DataDiffContainer,
    ExportWord,
    AttachmentsView,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class NotifyDetail extends Vue {
  detail: FundModifyApplyDto = {};
  dataId?: number;
  fundId?: number = 0;
  fundDetail: FundDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "基金修改审核详情";
  hostType = AttachmentHostType.FundModifyApply;
  fundHostType = AttachmentHostType.Fund;
  instructionsImageArray: string[] = [];
  plaqueImageArray: string[] = [];
  fundInstructionsImageArray: string[] = [];
  fundPlaqueImageArray: string[] = [];

  loading = true;


  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }
  // 获取详情
  async fetchDetail() {
    this.loading = true;
    api.fundModifyApply.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };

      if (this.detail && this.detail.instructionsImage) {
        this.instructionsImageArray = this.detail.instructionsImage.split(",");
      }
      if (this.detail && this.detail.plaqueImage) {
        this.plaqueImageArray = this.detail.plaqueImage.split(",");
      }
      if (this.detail && this.detail.fundID) {
        this.fundId = this.detail.fundID;
        this.loading = true;
        api.fund.get({ id: this.detail.fundID }).then((res) => {
          this.fundDetail = { ...res };
          if (this.fundDetail && this.fundDetail.instructionsImage) {
            this.fundInstructionsImageArray =
              this.fundDetail.instructionsImage.split(",");
          }
          if (this.fundDetail && this.fundDetail.plaqueImage) {
            this.fundPlaqueImageArray = this.fundDetail.plaqueImage.split(",");
          }
          this.loading = false;
        });
      }
      this.loading = false;
    });
  }

  get auditApi(){
    return {canAudit:api.fundModifyApply.canAudit,userAudit:api.fundModifyApply.audit} as AuditApi
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

}
