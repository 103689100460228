











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DataDiffTitle",
})
export default class DataDiffTitle extends Vue {
  @Prop({ required: true })
  title!: string;
}
